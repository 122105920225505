import Context from './Context';
import SunStudioContext from './SunStudioContext';
import { ROLES } from '@/model/shared/roles';
import { ECOMMERCE } from '@/model/shared/permissions/ecommerce';
import { DASHBOARD_SSP } from '@/model/shared/permissions/dashboardSSP';
import { THIRD_PARTY } from '@/model/shared/permissions/thirdParty';
import { SOCIAL_AUDIENCE } from '@/model/shared/permissions/socialAudience';
import { CONTEXTUAL } from '@/model/shared/permissions/contextual';

export const CONTEXTS = {
  ECOMMERCE: new Context(
    'ECOMMERCE',
    'Ecommerce',
    ECOMMERCE,
    'ecommerce',
    'analytics',
    {
      [ROLES.ADMIN.id]: [ROLES.AD_OPS, ROLES.PUBLISHER, ROLES.CLIENT],
      [ROLES.AD_OPS.id]: [ROLES.AD_OPS, ROLES.PUBLISHER, ROLES.CLIENT],
      [ROLES.CLIENT.id]: [ROLES.PUBLISHER, ROLES.CLIENT],
      [ROLES.PUBLISHER.id]: [],
      [ROLES.SUPER_ADVERTISER.id]: [],
      [ROLES.ADVERTISER.id]: [],
    },
    [ROLES.CLIENT, ROLES.AD_OPS, ROLES.PUBLISHER]
  ),
  DASHBOARD_SSP: new Context(
    'DASHBOARD_SSP',
    'Programmatic Campaign Dashboard',
    DASHBOARD_SSP,
    'dashboard-ssp',
    'analytics',
    {
      [ROLES.ADMIN.id]: [ROLES.CLIENT, ROLES.SELLER],
      [ROLES.SELLER.id]: [ROLES.CLIENT, ROLES.SELLER],
      [ROLES.OFFICE_OWNER.id]: [ROLES.CLIENT],
    },
    [ROLES.OFFICE_OWNER, ROLES.SELLER]
  ).setShortName('Programmatic Campaign'),
  SOCIAL_AUDIENCE: new Context(
    'SOCIAL_AUDIENCE',
    'Social Audience',
    SOCIAL_AUDIENCE,
    'social-audience',
    'analytics',
    {
      [ROLES.ADMIN.id]: [ROLES.AD_OPS, ROLES.ADVERTISER, ROLES.CLIENT],
      [ROLES.AD_OPS.id]: [ROLES.AD_OPS, ROLES.ADVERTISER, ROLES.CLIENT],
      [ROLES.CLIENT.id]: [ROLES.ADVERTISER],
      /* [ROLES.ADVERTISER.id]: [], */
    },
    [ROLES.CLIENT, ROLES.AD_OPS]
  ),
  THIRD_PARTY: new Context('THIRD_PARTY', 'Direct Campaign Dashboard', THIRD_PARTY, 'third-party', 'analytics', {
    [ROLES.ADMIN.id]: [ROLES.ADVERTISER, ROLES.CLIENT],
    [ROLES.SELLER.id]: [ROLES.SELLER],
  }).setShortName('Direct Campaign'),
  SUNSTUDIO: new SunStudioContext(),
  CORE: new Context('CORE', 'Core', THIRD_PARTY, '', '', {
    [ROLES.ADMIN.id]: [ROLES.USER],
    [ROLES.USER.id]: [],
  }),
  CONTEXTUAL: new Context('CONTEXTUAL', 'Contextual', CONTEXTUAL, 'contextual', 'cluster-list', {
    [ROLES.ADMIN.id]: [],
    [ROLES.AD_OPS.id]: [],
    [ROLES.SALES.id]: [],
  }),
};
export const AVAILABLE_CONTEXTS = {
  CORE: CONTEXTS.CORE,
  THIRD_PARTY: CONTEXTS.THIRD_PARTY,
  DASHBOARD_SSP: CONTEXTS.DASHBOARD_SSP,
  ECOMMERCE: CONTEXTS.ECOMMERCE,
  SOCIAL_AUDIENCE: CONTEXTS.SOCIAL_AUDIENCE,
  SUNSTUDIO: CONTEXTS.SUNSTUDIO,
};
